<template>
	<!-- 전체 -->
	<div id="rootVM" v-cloak>
		<div id="pageTitle">
			<b>AI기본설정</b>
		</div>
		<!-- 현장 조회 -->
		<div class="panel panel-flat">
			<div class="panel-body" style="width: 980px; margin: 0px 20px -20px auto; padding: 20px 0px">
				<div class="row">
					<!-- 조회할 현장 -->
					<div class="col-md-4" style="margin-left: 100px">
						<div class="form-group">
							<label style="padding-left: 13px; margin-right: -30px">{{ detailsFieldMap.searchSite }}</label>
							<select2 v-model="searchSite" :options="options.siteOptions" :disabled="isSiteDisabled" @input="searchSiteInfo">
								<option value="">선택</option>
							</select2>
						</div>
					</div>
					<!-- 검색 버튼 -->
					<!-- <div class="mb-10 text-right" style="float: right">
						<button @click="searchSiteInfo" class="btn btn-labeled bg-primary">
							<b><i class="icon-search4"></i></b>
							조회
						</button>
					</div> -->
				</div>
			</div>
		</div>
		<!-- // 현장 복사 및 현장 조회 -->

		<!-- AI 기본 설정 -->
		<div class="panel panel-flat">
			<div class="panel-body" style="padding-top: 20px">
				<div class="row" style="display: flex">
					<!-- 위험(주의) 판단 발생 기준 -->
					<div class="ai-config-all col-md-4">
						<label>{{ detailsFieldMap.dangerJugeStandardSec }}:&ensp;</label>
						<span style="font-weight: bold">{{ aiConfigAll.dangerJugeStandardSec }}</span>
						<label>&ensp;초</label>
					</div>
				</div>
			</div>
		</div>
		<div class="row" style="display: flex">
			<!-- // AI 기본 설정 -->
			<div class="col-md-6" style="padding-right: 0px">
				<!-- 주의 작업 설정 -->
				<div id="warn-table" class="table-wrapper" warnWork>
					<table class="table table-hover">
						<thead>
							<tr>
								<th>주의 작업</th>
								<th>주의 판단 발생 기준 (초)</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(row, index) in aiConfigAllWarnWork" :key="index" :id="'warn' + index" class="warn-tr">
								<td>{{ row.objectNm }}</td>
								<td>
									<input
										type="number"
										v-model="row.warnWorkJugeStandardSec"
										@keyup="warnWorkCheckLength('warnWorkJugeStandardSec', index, $event), checkWarnValues(index)"
									/>
								</td>
							</tr>
							<tr empty>
								<td colspan="6">조회된 항목이 없습니다.</td>
							</tr>
							<tr id="warn-table-tr-for-draw-line" style="padding: 0px">
								<td style="padding: 0px"></td>
								<td style="padding: 0px"></td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="mb-10 mt-10 text-right">
					<button @click="saveAiConfigAllWarnWork" class="btn btn-labeled bg-primary">
						<b><i class="icon-checkmark3"></i></b>
						적용
					</button>
				</div>
			</div>
			<!-- // 주의 작업 설정 -->
			<!-- 대상물 조치사항 설정 -->
			<div class="col-md-6" style="padding-right: 0px">
				<div id="danger-table" class="table-wrapper" danger>
					<table class="table table-hover">
						<thead>
							<tr>
								<th>대상물</th>
								<th>조치사항</th>
								<th>위험 판단 발생 기준 (초)</th>
								<th>위험 판단 발생 기준 (퍼센트)</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(row, index) in aiConfigAllDanger" :key="index" :id="'danger' + index" class="danger-tr">
								<td>{{ row.objectNm }}</td>
								<td>{{ row.targetNm }}</td>
								<td>
									<input
										type="number"
										v-model="row.dangerJugeStandardSec"
										@keyup="dangerWorkCheckLength('dangerJugeStandardSec', index, $event), checkDangerValues(index)"
									/>
								</td>
								<td>
									<input
										type="number"
										v-model="row.dangerJugeStandardDgre"
										@keyup="dangerWorkCheckLength('dangerJugeStandardDgre', index, $event), checkDangerValues(index)"
									/>
								</td>
							</tr>
							<tr empty>
								<td colspan="7">조회된 항목이 없습니다.</td>
							</tr>
							<tr id="danger-table-tr-for-draw-line" style="padding: 0px">
								<td style="padding: 0px"></td>
								<td style="padding: 0px"></td>
								<td style="padding: 0px"></td>
								<td style="padding: 0px"></td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="mt-10 text-right" style="margin-bottom: 15px">
					<button @click="saveAiConfigAllDanger" class="btn btn-labeled bg-primary">
						<b><i class="icon-checkmark3"></i></b>
						적용
					</button>
				</div>
			</div>
			<!-- // 대상물 조치사항 설정 -->
		</div>
	</div>
	<!-- // 전체 -->
</template>

<script>
import Select2 from '@/components/Select2';
import apiIndex from '../../api/index';
import _ from 'lodash';

let axiosExtention; //eslint-disable-line no-unused-vars
const basicAiConfigApi = apiIndex.basicAiConfig;

const maxLength = {
	dangerJugeStandardSec: '6',
};

export default {
	components: {
		Select2: Select2,
	},
	created() {
		this.pageParam = JSON.parse(sessionStorage.getItem('basicAiConfigPageParam'));
		if (!this.pageParam) location.href = apiIndex.mainUrl;
		if (this.pageParam.loginUserRolecd !== '00') {
			this.isSiteDisabled = true;
		}
		this.pageParam.siteOptions.forEach(e => {
			e.text = e.siteNm;
			e.value = e.siteId;
		});
		this.options.siteOptions = this.pageParam.siteOptions;
		this.searchSite = this.pageParam.loginUserSiteId;
		axiosExtention = this.$jarvisExtention.axiosExtention;
		this.init();
	},
	mounted() {},
	data: () => ({
		pageParam: null,
		options: {
			siteOptions: '',
		},
		//상세필드명맵
		detailsFieldMap: {
			searchSite: '현장 조회',
			dangerJugeStandardSec: '기본 위험(주의) 판단 발생 기준',
		},
		maxLength,
		debug: false,
		otherSite: null,
		copySite: null,
		searchSite: null,
		aiConfigAll: {
			// 위험 판단 기준 (초)
			dangerJugeStandardSec: 0,
		},
		originalAiConfigAllWarnWork: [],
		originalAiConfigAllDanger: [],
		aiConfigAllWarnWork: [],
		aiConfigAllDanger: [],
		isSiteDisabled: false,
	}),
	computed: {},
	watch: {},
	methods: {
		checkDangerValues(index) {
			let element = document.getElementById('danger' + index);
			if (
				this.originalAiConfigAllDanger[index].dangerJugeStandardSec != this.aiConfigAllDanger[index].dangerJugeStandardSec ||
				this.originalAiConfigAllDanger[index].dangerJugeStandardDgre != this.aiConfigAllDanger[index].dangerJugeStandardDgre
			) {
				element.classList.add('changed-value');
			} else {
				element.classList.remove('changed-value');
			}
		},
		checkWarnValues(index) {
			let element = document.getElementById('warn' + index);
			if (this.originalAiConfigAllWarnWork[index].warnWorkJugeStandardSec != this.aiConfigAllWarnWork[index].warnWorkJugeStandardSec) {
				element.classList.add('changed-value');
			} else {
				element.classList.remove('changed-value');
			}
		},
		checkLength(dataNm, value) {
			value = this.$jarvisExtention.inputDataController.onlyNumLimitLen(value, this.maxLength.duplicateAlertDiscgeSec);
			return value;
		},
		settingAllCheckLength(dataNm, event) {
			let target = event.target;
			let value = target.value;
			value = this.checkLength(dataNm, value);
			target.value = value;
		},
		warnWorkCheckLength(dataNm, index, event) {
			let target = event.target;
			let value = target.value;
			value = this.checkLength(dataNm, value);
			this.aiConfigAllWarnWork[index][dataNm] = value;
			target.value = value;
		},
		dangerWorkCheckLength(dataNm, index, event) {
			let target = event.target;
			let value = target.value;
			value = this.checkLength(dataNm, value);
			this.aiConfigAllDanger[index][dataNm] = value;
			target.value = value;
		},
		isNotNumeric(input) {
			let checkNum = /[0-9]/;
			let strInput = String(input);

			if (checkNum.test(strInput) == false) {
				return true;
			}
			return false;
		},
		init() {
			if (this.pageParam.inqAiConfigAll != null) {
				this.aiConfigAll.dangerJugeStandardSec = this.pageParam.inqAiConfigAll.dangerJugeStandardSec;
				this.aiConfigAll.dangerJugeStandardDgre = this.pageParam.inqAiConfigAll.dangerJugeStandardDgre;
			}
			this.searchSite = this.pageParam.loginUserSiteId;
			this.searchSiteInfo();
		},
		getTrSizeForDrawingTableLines(tableElement, childElement) {
			this.$nextTick(function () {
				let tableHeight = tableElement.clientHeight;
				let nodesHeight = tableElement.childNodes[0].clientHeight;
				let lineHeight = tableHeight - nodesHeight;
				childElement.setAttribute('style', 'height:' + lineHeight + 'px');
				if (lineHeight > 0 && childElement.clientHeight == 0) {
					this.getTrSizeForDrawingTableLines(tableElement, childElement);
				}
			});
		},
		async getAiConfigAll() {
			await this.$axios
				.post(basicAiConfigApi.inqAiConfigAll, { siteId: this.searchSite })
				.then(
					function (r) {
						this.aiConfigAll = r.data;
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
		},
		getAiConfigAllWarnWork() {
			var closure = this;
			this.$axios
				.post(basicAiConfigApi.inqAiConfigAllWarnWork, { siteId: this.searchSite })
				.then(
					function (r) {
						this.aiConfigAllWarnWork = r.data;
						if (r.data.length != 0) {
							let warnTable = document.getElementById('warn-table');
							let tr = document.getElementById('warn-table-tr-for-draw-line');
							closure.getTrSizeForDrawingTableLines(warnTable, tr);
						}
						this.viewAiConfigWarnWork();
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
		},
		getAiConfigAllDangerWork() {
			var closure = this;
			this.$axios
				.post(basicAiConfigApi.inqAiConfigAllDanger, { siteId: this.searchSite })
				.then(
					function (r) {
						this.aiConfigAllDanger = r.data;
						if (r.data.length != 0) {
							let dangerTable = document.getElementById('danger-table');
							let tr = document.getElementById('danger-table-tr-for-draw-line');
							closure.getTrSizeForDrawingTableLines(dangerTable, tr);
						}
						this.viewAiConfigDanger();
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
		},
		// 현장의 설정 정보들 조회
		searchSiteInfo() {
			if (!this.searchSite) {
				alert('현장을 선택해주세요.');
				return;
			}
			this.getAiConfigAll();
			this.getAiConfigAllWarnWork();
			this.getAiConfigAllDangerWork();
		},
		viewAiConfigWarnWork() {
			this.aiConfigAllWarnWork.forEach(e => {
				e.siteId = this.searchSite;
				if (e.warnWorkJugeStandardSec == null) {
					e.warnWorkJugeStandardSec = this.aiConfigAll.dangerJugeStandardSec;
				}
			});
			this.originalAiConfigAllWarnWork = _.cloneDeep(this.aiConfigAllWarnWork);
			document.querySelectorAll('.warn-tr').forEach(element => {
				element.classList.remove('changed-value');
			});
		},
		changeAiConfigAllWarnWork() {
			this.aiConfigAllWarnWork.forEach(e => {
				e.siteId = this.searchSite;
				e.warnWorkJugeStandardSec = this.aiConfigAll.dangerJugeStandardSec;
			});
		},
		viewAiConfigDanger() {
			this.aiConfigAllDanger.forEach(e => {
				e.siteId = this.searchSite;
				if (e.dangerJugeStandardSec == null) {
					e.dangerJugeStandardSec = this.aiConfigAll.dangerJugeStandardSec;
				}
				if (e.dangerJugeStandardDgre == null) {
					e.dangerJugeStandardDgre = this.aiConfigAll.dangerJugeStandardDgre;
				}
			});
			this.originalAiConfigAllDanger = _.cloneDeep(this.aiConfigAllDanger);
			document.querySelectorAll('.danger-tr').forEach(element => {
				element.classList.remove('changed-value');
			});
		},
		changeAiConfigAllDanger() {
			this.aiConfigAllDanger.forEach(e => {
				e.siteId = this.searchSite;
				e.dangerJugeStandardSec = this.aiConfigAll.dangerJugeStandardSec;
			});
		},
		// 돌면서 달라진 부분이 있는 것만 VOs에 넣음
		putValuesIfAiConfigAllWarnWorkHasChangedValue(lengthOfAiConfigAllWarnWork, VOs) {
			for (let i = 0; i < lengthOfAiConfigAllWarnWork; i++) {
				let cur = this.aiConfigAllWarnWork[i];
				let origin = this.originalAiConfigAllWarnWork[i];
				if (cur.warnWorkJugeStandardSec != origin.warnWorkJugeStandardSec) {
					VOs.push(cur);
				}
			}
		},
		checkAiConfigAllWarnWorkValues(lengthOfAiConfigAllWarnWork) {
			for (let i = 0; i < lengthOfAiConfigAllWarnWork; i++) {
				let curElement = this.aiConfigAllWarnWork[i];
				if (this.isNotNumeric(curElement.warnWorkJugeStandardSec)) {
					alert('입력 값은 숫자만 가능합니다.');
					return false;
				}

				if (curElement.warnWorkJugeStandardSec > 100000 || curElement.warnWorkJugeStandardSec < 5) {
					alert('값은 5 ~ 100,000 까지 입력 가능합니다.');
					return false;
				}
			}
			return true;
		},
		saveAiConfigAllWarnWork() {
			let lengthOfAiConfigAllWarnWork = this.aiConfigAllWarnWork.length;
			if (this.checkAiConfigAllWarnWorkValues(lengthOfAiConfigAllWarnWork) == false) {
				return;
			}
			if (confirm('적용하시겠습니까?')) {
				const insAiConfigAllWarnWorkWrapperInVO = {};
				// insAiConfigAllWarnWorkWrapperInVO.insAiConfigAllWarnWorkInVOs = this.aiConfigAllWarnWork;
				insAiConfigAllWarnWorkWrapperInVO.insAiConfigAllWarnWorkInVOs = [];
				insAiConfigAllWarnWorkWrapperInVO.originalInsAiConfigAllWarnWorkInVOs = this.originalAiConfigAllWarnWork;
				this.putValuesIfAiConfigAllWarnWorkHasChangedValue(
					lengthOfAiConfigAllWarnWork,
					insAiConfigAllWarnWorkWrapperInVO.insAiConfigAllWarnWorkInVOs,
				);
				this.$axios
					.post(basicAiConfigApi.saveAiConfigAllWarnWork, insAiConfigAllWarnWorkWrapperInVO)
					.then(
						function () {
							this.getAiConfigAllWarnWork();
							alert('적용되었습니다.');
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
			}
		},
		checkAiConfigAllDangerValues(lengthOfAiConfigAllDangerWork) {
			for (let i = 0; i < lengthOfAiConfigAllDangerWork; i++) {
				let curElement = this.aiConfigAllDanger[i];
				if (this.isNotNumeric(curElement.dangerJugeStandardSec)) {
					alert('입력 값은 숫자만 가능합니다.');
					return false;
				}
				if (curElement.dangerJugeStandardSec > 100000 || curElement.dangerJugeStandardSec < 5) {
					alert('값은 5 ~ 100,000 까지 입력 가능합니다.');
					return false;
				}

				if (this.isNotNumeric(curElement.dangerJugeStandardDgre)) {
					alert('입력 값은 숫자만 가능합니다.');
					return false;
				}
				if (curElement.dangerJugeStandardDgre > 100 || curElement.dangerJugeStandardDgre < 1) {
					alert('값은 1 ~ 100 까지 입력 가능합니다.');
					return false;
				}
			}
			return true;
		},
		// 돌면서 달라진 부분이 있는 것만 VOs에 넣음
		putValuesIfAiConfigAllDangerHasChangedValue(lengthOfAiConfigAllDangerWork, VOs) {
			for (let i = 0; i < lengthOfAiConfigAllDangerWork; i++) {
				let cur = this.aiConfigAllDanger[i];
				let origin = this.originalAiConfigAllDanger[i];
				if (cur.dangerJugeStandardSec != origin.dangerJugeStandardSec || cur.dangerJugeStandardDgre != origin.dangerJugeStandardDgre) {
					VOs.push(cur);
				}
			}
		},
		saveAiConfigAllDanger() {
			let lengthOfAiConfigAllDangerWork = this.aiConfigAllDanger.length;
			if (this.checkAiConfigAllDangerValues(lengthOfAiConfigAllDangerWork) == false) {
				return;
			}
			if (confirm('적용하시겠습니까?')) {
				const insAiConfigAllDangerWrapperInVO = {};
				// insAiConfigAllDangerWrapperInVO.insAiConfigAllDangerInVOs = this.aiConfigAllDanger;
				insAiConfigAllDangerWrapperInVO.insAiConfigAllDangerInVOs = [];
				insAiConfigAllDangerWrapperInVO.originalInsAiConfigAllDangerInVOs = this.originalAiConfigAllDanger;
				this.putValuesIfAiConfigAllDangerHasChangedValue(lengthOfAiConfigAllDangerWork, insAiConfigAllDangerWrapperInVO.insAiConfigAllDangerInVOs);
				this.$axios
					.post(basicAiConfigApi.saveAiConfigAllDanger, insAiConfigAllDangerWrapperInVO)
					.then(
						function () {
							this.getAiConfigAllDangerWork();
							alert('적용되었습니다.');
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
			}
		},
	},
};
</script>

<style scoped>
.content {
	padding-bottom: 20px;
}

[v-cloak] > * {
	display: none !important;
}

#rootVM > .panel .panel-body .form-group {
	display: flex;
	align-items: center;
}
#rootVM > .panel .panel-body .form-group label {
	width: 60%;
	margin-top: 5px;
	max-width: 130px;
}

.row .ai-config-all {
	display: flex;
	float: left;
	flex: 1;
	justify-content: center;
	align-items: center;
}
.ai-config-all > label {
	margin-top: 8px;
}
.ai-config-all > input {
	margin-left: 20px;
	margin-right: 10px;
	width: 62px;
	text-align: center;
}

/* table */
.table-wrapper {
	border-collapse: collapse;

	overflow-y: auto;
	border-color: #dedee0;
	border-style: solid;
	border-width: 1px 1px 1px 1px;
	position: relative;
	color: #515967;

	background-color: #fff;
}

[warnWork] {
	height: 50vh;
}
[danger] {
	/* height: 290px; */
	height: 50vh;
}

/* table head */
.table-wrapper .table > thead > tr > th {
	background-color: #f5f5f5;

	text-align: center;

	padding: 0.5em 0.6em 0.4em 0.6em;
	font-weight: 700;
	white-space: nowrap;
	text-overflow: ellipsis;

	border-color: #dedee0;
	border-style: solid;
	border-width: 0 0 0 1px;

	overflow: auto;
	position: sticky;
	top: 0;
	box-shadow: inset 0 -1px 0 #dedee0;
}

.table-wrapper .table > thead > tr > th:first-child {
	border-width: 0 0 0 0;
}

/* table body */
/* 홀수 */
.table-wrapper .table > tbody > tr:nth-child(2n + 1) {
	background-color: #fff;
}
/* 짝수 */
.table-wrapper .table > tbody > tr:nth-child(2n) {
	background-color: #fbfbfb;
}

.table-wrapper .table > tbody > tr:hover {
	background-color: #b5bdcb;
	background-image: url(/lib/kendo-r2-2019/styles/textures/highlight.png);
	background-position: 50% 50%;
}

.table-wrapper .table > tbody > tr > td:first-child {
	border-width: 0 0 0 0;
	text-align: center;
}

.table-wrapper .table > tbody > tr > td {
	border-color: #dedee0;
	border-style: solid;
	border-width: 0 0 0 1px;
	padding: 0.4em 0.6em;
	overflow: hidden;
	line-height: 1.6em;
	vertical-align: middle;
	text-overflow: ellipsis;
}

.table-wrapper .table > tbody > tr > td:not(:first-child) > input {
	border: none;
	width: 100%;
	height: 100%;
	background-color: transparent;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type='number'] {
	-moz-appearance: textfield;
	text-align: right;
}

/* table empty */
.table [empty] {
	display: none;
	height: 166px;
}
.table [empty] td {
	padding: 30px;
	font-size: 1.2em;
	text-align: center;
}
.table [empty]:first-child:last-child {
	display: table-row;
}
.col-md-3 {
	width: 265px;
}

.changed-value {
	background-color: yellow !important;
}
</style>
